@tailwind base;
@tailwind components;
@tailwind utilities;

/* .surface {
  @apply bg-white dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600;
} */

:root {
  --text-color-secondary: theme('colors.gray.500');

  --background: theme('colors.gray.100');
  --surface: theme('colors.white');
  --surface-overlay: theme('colors.gray.200');
  --underlay: theme('colors.gray.200');

  --divider: theme('colors.gray.400');
  --skeleton: theme('colors.gray.300');

  --primary: theme('colors.blue.900');
  --primary-overlay: theme('colors.white');
  --positive: theme('colors.green.500');
}

html.dark {
  --text-color-secondary: theme('colors.gray.300');

  --background: theme('colors.gray.800');
  --surface: theme('colors.gray.700');
  --surface-overlay: theme('colors.gray.600');

  --divider: theme('colors.gray.600');
  --skeleton: theme('colors.gray.600');

  --primary: theme('colors.blue.600');
  --primary-overlay: theme('colors.white');
}

.with-overlay {
  @apply relative cursor-pointer;

  &::after {
    content: '';
    border-radius: inherit;

    @apply absolute inset-0 bg-black bg-opacity-025 hidden;
  }

  &:hover::after {
    @apply block;
  }
}
.dark .with-overlay::after {
  @apply bg-opacity-10;
}

html,
body {
  height: 100%;
}

body {
  /* @apply tracking-wide; */

  /* font-family: "Poppins"; */
  font-size: 1rem;
  @apply text-gray-800 dark:text-white;
  /* color: hsl(210, 89%, 22%); */



  /* reconsider different font? */
  /* -webkit-font-smoothing: antialiased; */
}

/* Links */

a {
  /* TODO: Make it text-info and not primary? */
  @apply text-primary underline hover:no-underline;
}

/* @variants dark {
  .hey {
    @apply text-white;
  }
} */

/* start */

/* h1, h2, h3, h4, h5, h6 {
  @apply text-gray-800 dark:text-white;
} */

p {
  @apply text-secondary;
}

/* end */

#root {
  @apply h-full flex flex-col;
}

/* Stack */

.stack-1,
.stack-4,
.stack-6 {
  /* @apply flex flex-col justify-start; */
  @apply flex flex-col;
}

.stack-1 > *,
.stack-4 > *,
.stack-6 > * {
  @apply mt-0 mb-0;
}

.stack-1 > * + * {
  @apply mt-1;
}
.stack-4 > * + * {
  @apply mt-4;
}
.stack-6 > * + * {
  @apply mt-6;
}

/* Lottie */

svg.path-stroke-current path {
  @apply stroke-current;
}

/* Switch */

.switch {
  @apply relative inline-block align-middle cursor-pointer select-none bg-transparent;
}

.switch .track {
  @apply w-12 h-6 bg-gray-300 rounded-full;
}

.switch .thumb {
  @apply transition-all duration-300 ease-in-out absolute top-0 left-0 w-6 h-6 bg-white border-2 border-gray-300 rounded-full;
}

.switch input[type="checkbox"]:checked ~ .thumb {
  @apply transform translate-x-full border-blue-900 dark:border-blue-600;
}

.switch input[type="checkbox"]:checked ~ .track {
  @apply transform transition-colors bg-blue-900 dark:bg-blue-600;
}

.switch input[type="checkbox"]:disabled ~ .track {
  @apply bg-gray-500 cursor-not-allowed;
}

.switch input[type="checkbox"]:disabled ~ .thumb {
  @apply bg-gray-100 border-gray-500 cursor-not-allowed;
}

.switch input[type="checkbox"]:focus + .track,
.switch input[type="checkbox"]:active + .track {
  @apply ring-2 ring-offset-2;
}

/* Radio */

.radio {
  @apply cursor-pointer flex items-center;
}

.radio .checkmark {
  @apply w-6 h-6 relative border-2 border-primary rounded-full mx-5;
}

.radio .checkmark::after {
  content: '';

  @apply absolute top-0.5 left-0.5 w-4 h-4 rounded-full bg-primary opacity-0 transition-opacity duration-150;
}

.radio input[type="radio"]:checked ~ .checkmark::after {
  @apply opacity-100;
}

.radio input[type="radio"]:disabled ~ .checkmark {
  @apply border-gray-500;

  &::after {
    @apply bg-gray-500;
  }
}

/* Checkbox */

.checkbox {
  @apply flex items-center;
}

.checkbox .checkmark {
  @apply w-6 h-6 relative border-2 border-primary rounded-lg mx-5;
}

.checkbox .checkmark::after {
  content: '';

  border-width: 0 2px 2px 0;

  @apply absolute top-0.5 left-1.5 w-2 h-3.5 border-primary transform rotate-45 opacity-0 transition-opacity duration-150;
}

.checkbox input[type="checkbox"]:checked ~ .checkmark::after {
  @apply opacity-100;
}

.checkbox input[type="checkbox"]:disabled ~ .checkmark {
  @apply border-gray-500;

  &::after {
    @apply border-gray-500;
  }
}

/* Headings */

/* h1,
h2,
h3,
h4,
h5,
h6 {
  color: unset;
} */

/* TODO: do the same for 1-6 headings */
.markdown h1 {
  @apply text-2xl font-medium;
}
.markdown h2 {
  @apply text-xl font-medium;
}
.markdown h3 {
  @apply text-lg font-medium;
}
.markdown > * + * {
  @apply mt-3;
}

/* .separator {
  width: 1px;
  z-index: -1;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='10%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
} */

.cluster {
  --space: 1rem;

  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: flex-start; */
  /* align-items: center; */
  margin: calc(var(--space) / 2 * -1);
}

.cluster > * {
  margin: calc(var(--space) / 2);
}

/* Circle */

.circle {
  @apply rounded-full;
}
.circle::before {
  content: '';
  padding-top: 100%;
}

/* FormField */

:root {
  --surface1: lch(10 0 0);
  --surface2: lch(15 0 0);
  --surface3: lch(20 0 0);
  --surface4: lch(25 0 0);

  --text1: lch(95 0 0);
  --text2: lch(75 0 0);

  --brand: lch(64 20 237);
  --brand-bg1: lch(70 64 349);
  --brand-bg2: lch(60 84 300);

  --brand-bg-gradient: linear-gradient(
    to bottom,
    var(--brand-bg1),
    var(--brand-bg2)
  );

  --thumb-highlight-color: lch(100 0 0 / 20%);

  --space-xxs: .25rem;
  --space-xs:  .5rem;
  --space-sm:  1rem;
  --space-md:  1.5rem;
  --space-lg:  2rem;
  --space-xl:  3rem;
  --space-xxl: 6rem;

  /* @media (prefers-color-scheme: light) { */
    /* & { */
      --surface1: lch(90 0 0);
      --surface2: lch(100 0 0);
      --surface3: lch(98 0 0);
      --surface4: lch(85 0 0);

      --text1: lch(20 0 0);
      --text2: lch(40 0 0);

      --brand: lch(64 40 237);
      /* --brand-bg1: lch(50 64 349);
      --brand-bg2: lch(40 84 300); */
      --brand-bg1: lch(25.399 54.051 292.841);
      --brand-bg2: lch(25.399 54.051 292.841);

      --thumb-highlight-color: lch(0 0 0 / 20%);
    /* } */
  /* } */
}

fieldset {
  /* border: 1px solid var(--surface4); */
  /* background: var(--surface4); */
  /* padding: 0; */
  /* margin: 0; */
  display: grid;
  gap: 1rem;
  /* border-radius: var(--space-sm); */
  /* overflow: hidden; */
  /* transition: box-shadow .3s ease; */

  /* &:focus-within {
    box-shadow: 0 5px 20px -10px hsl(0 0% 0% / 50%); */
  /* } */
}
